import U929NB from "./images/U929NB.jpg"
import U939NB from "./images/U939NB.jpg"
import U939BLW from "./images/U939BLW.jpg"
import U939BLS from "./images/U939BLS_new.png"
import U939NB_P from "./images/U939NB_P_new.png"
import U939BLM from "./images/U939BLM_new.png"
import U969NB from "./images/U969NB.png"
import lpg管理系统 from "./images/lpg管理系统.jpg"
import 优气宝小程序 from "./images/优气宝小程序.jpg"
import 优气达APP from "./images/优气达APP.jpg"
import lpg安监管理系统 from "./images/lpg安监管理系统.jpg"
import U929NBdetail from "./images/U929NBdetail.png"
import U939NBdetail from "./images/U939NBdetail.png"
import U939NB_Pdetail from "./images/U939NB_P_newdetail.jpg"
import U939BLWdetail from "./images/U939BLWdetail.jpg"
import U939BLSdetail from "./images/U939BLS_newdetail.jpg"
import U939BLMdetail from "./images/U939BLM_newdetail.jpg"
import U969NBdetail from "./images/U969NBdetail.jpg"
import icon from "./images/icon.png"
import 优气物联detail from "./images/优气物联detail.png"
import 优气宝小程序detail from "./images/优气宝小程序detail.png"
import lpg安监管理系统detail from "./images/lpg安监管理系统detail.png"
import 优气达APPdetail from "./images/优气达APPdetail.png"
export default {
  intelligentHardware: {
    name: "智能硬件",
    category: [
      {
        img: U929NB,
        name: "U929系列",
        id: 1,
        icon
      },
      {
        img: U929NB,
        name: "U939系列",
        id: 2,
        icon
      }
    ],
    list: [
      {
        name: "U929NB型智能钢瓶称重底座",
        code: "U929NB",
        img: U929NB,
        detail: [U929NBdetail],
        catId: 1,
        id: 1
      },
      // {
      //   name: "U939NB型燃气泄漏报警器",
      //   code: "U939NB",
      //   img: U939NB,
      //   detail: [U939NBdetail],
      //   catId: 2,
      //   id: 2
      // },
      {
        name: "U939BLW智能钢瓶称重底座",
        code: "U939BLW",
        img: U939BLW,
        detail: [U939BLWdetail],
        catId: 2,
        id: 3
      },
      {
        name: "U939BLS型燃气自动切断阀",
        code: "U939BLS",
        img: U939BLS,
        detail: [U939BLSdetail],
        catId: 2,
        id: 4
      },
      {
        name: "U939NB_P型燃气泄漏报警器",
        code: "U939NB_P",
        img: U939NB_P,
        detail: [U939NB_Pdetail],
        catId: 2,
        id: 5
      },
      {
        name: "U939BLM燃气自动机械手",
        code: "U939BLM",
        img: U939BLM,
        detail: [U939BLMdetail],
        catId: 2,
        id: 6
      },
      {
        name: "GTY-U969NB工业报警器",
        code: "U969NB",
        img: U969NB,
        detail: [U969NBdetail],
        catId: 2,
        id: 7
      }
    ]
  },
  softwarePlatform: {
    name: "软件平台",
    category: [
      {
        img: U929NB,
        name: "优气物联",
        id: 1,
        icon
      },
      {
        img: U929NB,
        name: "优气宝小程序",
        id: 2,
        icon
      },
      {
        img: U929NB,
        name: "优气达APP",
        id: 3,
        icon
      }
    ],
    list: [
      {
        name: "LPG优气物联网管理系统",
        code: "软件平台",
        img: lpg管理系统,
        detail: [优气物联detail],
        catId: 1,
        id: 10
      },
      {
        name: "优气宝小程序",
        code: "软件平台",
        img: 优气宝小程序,
        detail: [优气宝小程序detail],
        catId: 2,
        id: 11
      },
      {
        name: "LPG安监管理系统",
        code: "软件平台",
        img: lpg安监管理系统,
        detail: [lpg安监管理系统detail],
        catId: 1,
        id: 12
      },
      {
        name: "优气达APP",
        code: "软件平台",
        img: 优气达APP,
        detail: [优气达APPdetail],
        catId: 3,
        id: 13
      }
    ]

  }
}