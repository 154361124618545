<template>
  <div class="product">
    <headerSwiper />
    <div class="content">
      <div v-if="keyword == undefined" class="product-category">
        <div class="product-category-title">
          <img src="@/assets/images/product/tag.png" alt="">
          <div>{{typeData.name}}</div>
        </div>
        <a :title="item.name" :href="'/product/?type='+type+'&category='+item.id" class="product-category-item" :class="category == item.id && 'product-category-item-act'" v-for="(item, index) in typeData.category" :key="index">
          <img :src="item.icon" alt="">
          <div>{{item.name}}</div>
        </a>
      </div>
      <div class="product-list" v-if="productList.length">
        <a :title="item.name" :href="'/product/detail/?id='+item.id" class="product-item" v-for="(item, index) in productList" :key="index">
          <img :src="item.img" alt="">
          <div>{{item.name}}</div>
          <div>{{item.code}}</div>
          <div v-if="type == 'intelligentHardware'">查看详情</div>
        </a>
      </div>
      <div v-else class="no-data">
        <img src="@/assets/images/no-data.png" alt="">
        <div>请输入其他关键字</div>

      </div>
    </div>
  </div>
</template>

<script>
import headerSwiper from "@/components/headerSwiper.vue"
import product from "@/assets/hypocritical/product.js"
// console.log(product,"product")
export default {
  metaInfo: {
    title: "深圳多知能源物联有限公司专注智能家居,烟雾报警器,燃气报警器",
    meta: [
      {
        name: "keywords",
        content: [...product.intelligentHardware.list, ...product.softwarePlatform.list].map(it => it.name)
      },
      {
        name: "description",
        content: "优气物联致力于“液化石油气LPG” 物联网应用软硬件产品的研发、生产、销售和服务。旨在为燃气企业赋能，降低成本，提升效率，为监管单位提供高效精准的监管渠道，为终端用户带来高质量的用气体验。打造一个聚焦LPG领域，融合新零售、采用大数据、物联网等前沿技术的一站式服务平台。",
      },
    ],
  },
  components: {headerSwiper},
  computed: {
    keyword(){
      return this.$route.query.keyword
    },
    type(){
      return this.$route.query.type
    },
    category(){
      return this.$route.query.category
    },
    typeData(){
      return product[this.type]
    },
    productList(){
      if(this.keyword == undefined){
        if(this.category){
          return product[this.type].list.filter(it => it.catId == this.category)
        }
        return product[this.type].list

      }else{
        const list = [...product.intelligentHardware.list, ...product.softwarePlatform.list]

        return list.filter(it => it.name.indexOf(this.keyword) != -1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product{
  background: #edf0f2;
}
.content{
  padding: 10px 0;
  max-width: 1600px;
  .product-category{
    display: flex;
    align-items: center;
    .product-category-title{
      width: 150px;
      height: 40px;
      position: relative;
      & > img{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
      }
      & > div{
        color: white;
        line-height: 40px;
        z-index: 1;
        position: relative;
        text-align: left;
        padding-left: 20px;
      }
    }
    .product-category-item{
      margin: 0 20px;
      cursor: pointer;
    }
    .product-category-item-act{
      & > div{
        color: #ffa829;
      }
    }
  }
  .product-list{
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    .product-item{
      width: 23%;
      margin: 10px 1%;
      background: white;
      cursor: pointer;
      overflow: hidden;
      img{
        width: 100%;
        margin: 10% 0 6% 0;
        transition: all .2s;
      }
      & > div:nth-child(2){
        color: #535353;
        line-height: 30px;
      }
      & > div:nth-child(3){
        color: #535353;
        font-size: 12px;
        line-height: 36px;
      }
      & > div:nth-child(4){
        font-size: 13px;
        width: fit-content;
        border: 1px solid #e3e3e3;
        padding: 6px 16px;
        border-radius: 6px;
        margin: 0 auto;
        margin-bottom: 20px;
        transition: all .2s;
      }
    }
    .product-item:hover{
      img{
        transform: scale(1.1);
      }
      & > div:nth-child(4){
        color: white;
        background: #2da0db;
      }
    }
  }
}
.no-data{
  margin: 100px 0;
  div{
    font-size: 14px;
    color: #535353;
  }
}
@media screen and (max-width: 600px) {
  .product-category{
    flex-wrap: wrap;
    .product-category-title{
      width: 100%!important;
      img{
        width: 150px!important;
      }
    }
    .product-category-item{
      div{
        font-size: 14px;
      }
    }
  }
  .product-list{
    & > a{
      width: 48%!important;
      & > div:nth-child(2){
        font-size: 12px;
      }

    }
  }
}
</style>